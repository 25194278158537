import userIdlePopupTemplate from '../../components/popup-view/user-idle-warning.html';

export default function(app) {
  app.factory('NewRecordsService', newRecordsService);

  function newRecordsService($rootScope, $timeout, sharedDataService, dataServices, serverUrl) {

    "ngInject";

    return {
      saveLastVisit: saveLastVisit,
      initLastVisit: initLastVisit,
      keepNewRecords: keepNewRecords
    };

    function initLastVisit(vm, model, boardId) {
      const newRecordsTS = sharedDataService.get('newRecordsTS') || {};

      let modelTS;
      if (boardId) {
        modelTS = newRecordsTS[model] && newRecordsTS[model][boardId] ? newRecordsTS[model][boardId] : undefined;
      } else {
        modelTS = newRecordsTS[model];
      }

      if (!modelTS || (modelTS && Date.now() > modelTS)) {
        $timeout(function() {
          if (angular.isUndefined(vm.noOfNewRecords)) {
            vm.noOfNewRecords = $('.new-record').length;
            console.log('noOfNewRecords', vm.noOfNewRecords);
          }
        }, 10);
      }
    }

    function keepNewRecords(vm, model, boardId) {
      const newRecordsTS = sharedDataService.get('newRecordsTS') || {};
      const nextDayTS = Date.now() + 86400000;
      if (boardId) {
        newRecordsTS[model] = newRecordsTS[model] || {};
        newRecordsTS[model][boardId] = nextDayTS;
      } else {
        newRecordsTS[model] = nextDayTS;
      }
      sharedDataService.set('newRecordsTS', newRecordsTS);
      vm.noOfNewRecords = 0;
    }

    function saveLastVisit(vm, modelId, boardId) {
      const lastVisitedAt = Math.trunc(Date.now() / 1000);

      const data = {
        lastVisitedAt,
        modelId,
        boardId
      };

      dataServices.post({
          url: serverUrl.main + "lastVisitedApi/last-visited",
          data: {
            data: angular.toJson(data)
          }
        })
        .then(function(response) {
          let data = response.data;
          if (data && data.response_code == 200) {
            vm.noOfNewRecords = 0;
            vm.lastVisitedAt = lastVisitedAt;
          }
        });
    }
  }

}