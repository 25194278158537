function UpgradeLicenseController($scope, $rootScope, $state, NavigationService) {

  "ngInject";

  const vm = this;

  vm.goBack = () => {
    NavigationService.back();
  };

}

export default UpgradeLicenseController;