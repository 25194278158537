export default function (app) {
  app.factory('validatorService', validatorService);

  function validatorService(messages, $log) {

    "ngInject";

    return {
      isValidForm: isValidForm,
      validateForm: validateForm,
      validateForms: validateForms,
      chipValidation: chipValidation
    };

    function chipValidation(chip) {
      if (chip.length < 51) {
        return chip;
      } else {
        messages.simpleToast("Chip greater than 50 is not allow.");
        return null;
      }
    }

    /**
     *  Validate single form and return error class to show in md-tabs
     *  Used in md-tabs
     *
     *  form: form to be validated
     */
    function isValidForm(form) {
      return form && form.$submitted && form.$invalid ? 'is-error' : '';
    }

    /**
     *  Validate single form
     *
     *  vm: reference of current controller instance
     *  callback: callback function to call upon successful validation
     */
    function validateForm(vm, callback, formName = 'myForm') {
      return function () {
        console.log(vm[formName]);
        if (vm[formName]) {
          vm[formName].$setSubmitted();
          if (vm[formName].$valid && angular.isFunction(callback)) {
            callback();
          } else {
            messages.simpleToast("Mandatory fields need to be filled");
            console.log(vm[formName], vm[formName].$valid);
          }
        } else {
          $log.log('no form');
        }
      };
    }

    /**
     *  Validate multiple forms
     *
     *  vm: reference of current controller instance
     *  forms: array fo forms to be validated
     *  callback: callback function to call upon successful validation
     */
    function validateForms(vm, forms, callback) {
      return function () {
        let inValidFormIndex = -1;
        $log.log(forms);
        angular.forEach(forms, function (formName, index) {
          console.log(formName, index, vm[formName]);
          if (typeof formName == 'string' && vm[formName]) {
            vm[formName].$setSubmitted();
            if (inValidFormIndex == -1 && vm[formName].$invalid) {
              // $log.log(index);
              console.log(vm[formName])
              vm.activeTabIndex = inValidFormIndex = index;
            }
          } else if (typeof formName == 'object' && formName.array) {
            angular.forEach(vm[formName.array], function (form, key) {
              if (form) {
                form.$setSubmitted();
                if (inValidFormIndex == -1 && form.$invalid) {
                  vm.activeTabIndex = inValidFormIndex = index;
                }
              }
            });
          }
        });

        if (inValidFormIndex == -1 && angular.isFunction(callback)) {
          callback();
        }
      };
    }
  }
}