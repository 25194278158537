import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import confirmDialogTemplate from '../../components/popup-view/confirm-dialog.html';

export default function(app) {
  app.factory('ScheduleService', ScheduleService);

  function ScheduleService($state, dataServices, serverUrl, $timeout, messages, maxlength, $mdDialog, $filter, validatorService, errorMessage) {

    "ngInject";

    return {
      init: init,
      scheduleEstimate: scheduleEstimate,
      assignUnassignResource: assignUnassignResource,
      selectUnselectResource: selectUnselectResource
    }

    // START: This function is for intialize the default variable for that service.
    function init(vm) {
      vm.selectResources = {};
    }
    // END: This function is for intialize the default variable for that service.

    // START: This function is called if you want to schedule your service from(Calendar, Estimate Detail and Scedule module of resources.). Firstly it will open the sidebar and show best resource for the estimates for scheduling.
    function scheduleEstimate(vm) {
      vm.toggleSchedule();

      let filterCriteria = {
        filters: [{
          field: "status",
          value: 2,
          operator: "="
        }]
      };

      vm.scheduleDataLoaded = false;

      dataServices.get({
        url: serverUrl.main + 'resourceApi/scheduleTask/estimates/list/' + vm.serviceId + '?',
        spinnerName: 'sidebar-spinner'
      }).then(function(response) {
        vm.scheduleDataLoaded = true;

        if (response.data && response.data.result) {
          vm.scheduleData = response.data.result;
          for (let i = 0; i < vm.scheduleData.length; i++) {
            vm.selectResources[vm.scheduleData[i].id] = {};

            for (let j = 0; j < vm.scheduleData[i].bestAvailResList.length; j++) {
              vm.selectResources[vm.scheduleData[i].id][vm.scheduleData[i].bestAvailResList[j].id] = false;
            }

            for (let j = 0; j < vm.scheduleData[i].resources.length; j++) {
              let index = findIndex(vm.scheduleData[i].bestAvailResList, function(resource) {
                return resource.id == vm.scheduleData[i].resources[j].resourceId;
              });
              vm.selectResources[vm.scheduleData[i].id][vm.scheduleData[i].resources[j].resourceId] = vm.scheduleData[i].bestAvailResList[index];
            }

          }

          if(vm.scheduleData.length == 1) {
            vm.status = [];
            vm.status[0] = true;
          }

          vm.alreadySelectedResource = angular.copy(vm.selectResources);

        }
      }, function(error) {
        vm.scheduleDataLoaded = true;
      });
    }
    // END: This function is called if you want to schedule your service from(Calendar, Estimate Detail and Scedule module of resources.). Firstly it will open the sidebar and show best resource for the estimates for scheduling.

    // START : You can assign the resource from the best resources we are showing in sidebar.
    function assignUnassignResource(vm, callback) {

      let data = {
        assign: [],
        unAssign: []
      };

      if (isEqual(vm.selectResources, vm.alreadySelectedResource)) {
        messages.simpleToast('Please select atleast one resource !');
        return;
      }

      angular.forEach(vm.selectResources, function(estimates, key) {
        if (Object.keys(estimates).length) {
          let isAtleastOneResourceSelected = false;
          angular.forEach(estimates, function(isSelected, id) {
            if (isSelected) {
              isAtleastOneResourceSelected = true;
            }
          });
          if (isAtleastOneResourceSelected) {

            data.assign.push({
              quoteId: vm.quoteId.toString(),
              taskId: vm.serviceId,
              id: key,
              resources: []
            });

            angular.forEach(estimates, function(resource, id) {
              if (resource && !vm.alreadySelectedResource[key][id]) {
                data.assign[data.assign.length - 1].resources.push({
                  resourceId: id
                });
              } else {
                if (!resource && vm.alreadySelectedResource[key][id]) {
                  data.unAssign.push({
                    quoteId: vm.quoteId,
                    taskId: vm.serviceId,
                    id: key,
                    resources: []
                  });

                  data.unAssign[data.unAssign.length - 1].resources.push({
                    resourceId: id
                  });
                }
              }
            });
            if (!data.assign[data.assign.length - 1].resources.length) {
              data.assign.splice((data.assign.length - 1), 1);
            }

          } else {
            if (Object.keys(vm.alreadySelectedResource[key]).length) {
              data.unAssign.push({
                quoteId: vm.quoteId,
                taskId: vm.serviceId,
                id: key,
                resources: []
              });

              angular.forEach(vm.alreadySelectedResource[key], function(resource, id) {
                if (vm.alreadySelectedResource[key][id] && !vm.selectResources[key][id]) {
                  data.unAssign[data.unAssign.length - 1].resources.push({
                    resourceId: id
                  });
                }
              });

              if (!data.unAssign[data.unAssign.length - 1].resources.length) {
                data.unAssign.splice((data.unAssign.length - 1), 1);
              }

            }
          }
        }
      });

      dataServices.put({
        url: serverUrl.main + 'taskApi/services/quotes/assign/unassign',
        data: {
          data: angular.toJson(data)
        }
      }).then(function(response) {
        let data = response.data;
        if (data && data.result) {
          if (data.result.hasOwnProperty('clockInTime') && data.result.hasOwnProperty('id')) {
            clockOut(vm, data.result, function() {
              assignUnassignResource(vm, callback);
            });
          } else {
            messages.simpleToast('Updated successfully!');
            // if (isCalendar) {
            //   vm.getServiceDetail(vm.quoteId, vm.serviceId);
            // }
            //
            // if (isRefresh) {
            //   vm.getAssignedTasks(vm.subDate, vm.dateSecAssign);
            // }

            vm.toggleSchedule();

            if (angular.isFunction(callback)) {
              callback();
            }
          }
        }
      });
    }

    function ClockoutController($mdDialog, messages, dataServices, serverUrl, vm, clockInDetail, callback) {

      'ngInject';

      let cd = this;

      cd.description = 'The resource you are trying to remove is actively clocked-in into this service. System will clock-out the resource automatically if you remove the resource.';

      cd.question = 'Do you wish to proceed?';

      cd.confirm = function() {
        dataServices.put({
            url: serverUrl.main + 'timeTrackingApi/track/out/' + clockInDetail.id,
            data: {
              data: angular.toJson({
                taskId: vm.serviceId,
                quote_id: vm.quoteId,
                clockOutTime: moment().unix(),
                clockOutAddress: {},
                isBreak: clockInDetail.isBreak
              })
            }
          })
          .then(function(response) {
            let data = response.data;
            if (data && data.response_code == 200) {
              cd.cancel();
              messages.simpleToast('Clocked Out.');
              if (angular.isFunction(callback)) {
                callback();
              }
            }
          });
      };

      cd.cancel = function() {
        $mdDialog.hide();
      };
    }

    function clockOut(vm, clockInDetail, callback) {
      $mdDialog.show({
        locals: {
          vm,
          clockInDetail,
          callback
        },
        controller: ClockoutController,
        templateUrl: confirmDialogTemplate,
        parent: angular.element(document.body),
        controllerAs: 'cd'
      });
    }
    // END : You can assign the resource from the best resources we are showing in sidebar.

    // START : This function is used for selecting and unselecting the resources from sidebar.
    function selectUnselectResource(vm, estimates, resource) {
      if (vm.selectResources[estimates.id][resource.id]) {
        vm.selectResources[estimates.id][resource.id] = false;
      } else {
        let selectedResourceCount = 0;
        angular.forEach(vm.selectResources[estimates.id], function(value, id) {
          if (value) {
            selectedResourceCount++;
          }
        });

        if (selectedResourceCount == estimates.number) {
          messages.simpleToast("All resources for this role type are already assigned. You could unassign first and reassign team members.", 'warning', 5000);
          return;

        } else {
          vm.selectResources[estimates.id][resource.id] = (vm.selectResources[estimates.id][resource.id] ? false : resource);
        }
      }

    }
    // END : This function is used for selecting and unselecting the resources from sidebar.
  }
}