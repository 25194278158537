import MainController from './../../pages/main/main.controller';
import RightController from './../../pages/main/right.controller';
import UpgradeLicenseController from './../../pages/main/upgrade-license/upgrade-license.controller';
import NoAccessController from './../../pages/main/no-access/no-access.controller';
import UpdateWorkorderController from './../../pages/main/update-workorder/update-workorder-main.controller';

export default angular.module('index.controller', [])
  .controller('MainController', MainController)
  .controller('UpgradeLicenseController', UpgradeLicenseController)
  .controller('NoAccessController', NoAccessController)
  .controller('UpdateWorkorderController', UpdateWorkorderController)
  .controller('RightCtrl', RightController);