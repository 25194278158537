export default function(app) {
  app.factory('contactData', contactData);

  function contactData($rootScope, $state, dataServices, messages, $filter, $window, serverUrl) {

    "ngInject";

    let count = 0;

    return {
      openUrl: openUrl,
      assignedData: assignedData,
      initSocialLinks: initSocialLinks,
      saveSocialAddress: saveSocialAddress,
      getViewContactDetail: getViewContactDetail,
      setSaveContactDetail: setSaveContactDetail,
      setEmailBounceStatus: setEmailBounceStatus,
      openSocialMedia: openSocialMedia
    };

    function openUrl(url) {
      $window.open((url.startsWith('http://') ? ('https://' + url.slice(7)) : (url.startsWith('https://') ? url : ('https://' + url))), '_blank');
    }

    function openSocialMedia(platform) {
      const socialMediaUrls = {
        instagram: 'https://www.instagram.com',
        facebook: 'https://www.facebook.com',
        twitter: 'https://www.twitter.com',
        linkedin: 'https://www.linkedin.com',
        telegram: 'https://web.telegram.org',
      };

      const baseUrl = socialMediaUrls[platform.toLowerCase()];

      if (baseUrl) {
        window.open(baseUrl, '_blank');
      } else {
        console.error('Platform not supported:', platform);
      }
    };

    function setSaveContactDetail(details, selectedItem) {

      let vm = {
        detail: angular.copy(details),
        selectedItem: angular.copy(selectedItem)
      };

      delete vm.detail.sContact;
      delete vm.detail.bAddress;
      delete vm.detail.sAddress;

      if ("billingAddress" in vm.detail) {
        delete vm.detail.billingAddress.searchZipcode;
      }

      if ("shippingAddress" in vm.detail) {
        delete vm.detail.shippingAddress.searchZipcode;
      }

      delete vm.detail.mailingAddress.searchZipcode;

      let detail = {};
      angular.copy(vm.detail, detail);
      angular.forEach(detail.primaryContact, function(value, key) {
        detail[key] = detail.primaryContact[key];
      });

      delete detail.primaryContact;

      var contactDetail = {};
      angular.copy(detail, contactDetail);
      detail = {};

      console.log(vm.selectedItem);
      if (vm.selectedItem && vm.selectedItem[0]) {
        contactDetail.customerId = vm.selectedItem[0].customerId;
      }

      detail = {
        quote: {
          addressId: contactDetail.addressId || vm.detail.addressId
        }
      };

      delete contactDetail.addressId;
      detail.contact = contactDetail;
      return detail;
    }

    function getViewContactDetail(vm, selectedItem, contactIndex) {

      // console.log(selectedItem, contactIndex, selectedItem);
      if (contactIndex) {
        vm.detail.sContact = false;
        vm.secondaryContact();
        if (vm.detail.secondContact && selectedItem) {
          assignContact(vm.detail.secondContact, selectedItem);
        }
      } else {
        assignContact(vm.detail.primaryContact, selectedItem);

        // console.log(vm.detail.secondContact);
        // if (vm.detail.secondContact) {
        //   assignContact(vm.detail.secondContact, selectedItem);
        // }

        vm.detail.mailingAddress = selectedItem.mailingAddress ? selectedItem.mailingAddress : {};

        if (angular.isArray(selectedItem.licenseInfo) && selectedItem.licenseInfo.length) {
          angular.forEach(selectedItem.licenseInfo, function(val, index) {
            if (val != '') {
              vm.licenseInfo.push({
                info: val
              });
            }
          });

        } else {
          vm.licenseInfo = [{
            info: ''
          }];
        }

        if (selectedItem.dob) {
          vm.dob = new Date(selectedItem.dob * 1000);
        }

        vm.address = [vm.detail.mailingAddress];
        if (selectedItem.billingAddress) {
          vm.detail.billingAddress = selectedItem.billingAddress;
          vm.detail.bAddress = true;
          vm.address.push(vm.detail.billingAddress);
        }

        if (selectedItem.shippingAddress) {
          vm.detail.shippingAddress = selectedItem.shippingAddress;
          vm.detail.sAddress = true;
          vm.address.push(vm.detail.shippingAddress);
        }

        vm.detail.description = selectedItem.description ? selectedItem.description : "";
        vm.detail.preference = selectedItem.preference ? selectedItem.preference : "";
        vm.detail.rating = selectedItem.rating ? selectedItem.rating : 1;
        // console.log("selectedItem", selectedItem);

        if (selectedItem.companyName) {
          vm.selectedItemCompany = {
            id: selectedItem.companyName,
            name: selectedItem.company
          };
          vm.detail.company = selectedItem.company;
        }

        if (selectedItem.title) {
          vm.detail.title = $rootScope.rObj.records.position[selectedItem.title].reason;
        }

        if (selectedItem.primaryCategoryId) {
          vm.detail.primaryCategoryId = selectedItem.primaryCategoryId;
        }

        if (selectedItem.secondaryCategoryId) {
          vm.detail.secondaryCategoryId = selectedItem.secondaryCategoryId;
        }

        if (vm.stateName == 'customers') {
          vm.detail.favorite = selectedItem.favorite;
          vm.detail.leadSource = selectedItem.leadSource;
        }
        vm.detail.website = selectedItem.website || [];

        // Need to change at last.
        vm.detail.businessAddress = selectedItem.businessAddress ? selectedItem.businessAddress : vm.detail.businessAddress;

        // console.log(vm.detail.shipping_address);
        // Set Verified Icons
        if (vm.detail.mailingAddress.lat && vm.detail.mailingAddress.lng) {
          vm.validateColor[0] = true;
        }
        if (vm.detail.billingAddress && vm.detail.billingAddress.lat && vm.detail.billingAddress.lng) {
          vm.validateColor.push(true);
        }
        if (vm.detail.shippingAddress && vm.detail.shippingAddress.lat && vm.detail.shippingAddress.lng) {
          vm.validateColor.push(true);
        }
      }
      // console.log(angular.copy(vm.detail));
    }

    function assignContact(cons, sData) {

      cons.firstName = sData.firstName;
      cons.middleName = sData.middleName;
      cons.lastName = sData.lastName;
      cons.suffix = sData.suffix;

      cons.email = [];
      angular.forEach(sData.email, function(value, key) {
        if (!cons.email[key]) {
          cons.email.push({});
        }
        cons.email[key].type = value.type;
        cons.email[key].id = value.id;
      });

      cons.phone = [];

      if (sData.phone && sData.phone.length) {
        angular.forEach(sData.phone, function(value, key) {
          if (!cons.phone[key]) {
            cons.phone.push({});
          }
          cons.phone[key].type = value.type;
          cons.phone[key].phone = value.phone;
          cons.phone[key].extension = value.extension;
          cons.phone[key].countryCode = value.countryCode
        });
      }

      if (!sData.phone || sData.phone && !sData.phone.length) {
        cons.phone.push({
          type: '',
          phone: '',
          extension: '',
          countryCode: ''
        });
      }
      // remove_length = cons.phone.length - sData.phone.length;
      // cons.email.splice(sData.phone.length, remove_length);
    }

    function clearDetail(detail) {
      if (detail) {
        delete detail.primaryContact;
      }
    }

    function saveSocialAddress(index, vm, id, isCompany) {
      console.log(id, vm.contact);
      // return;
      dataServices.post({
        url: serverUrl.main + (isCompany ? 'companyApi/company/' : 'contactApi/customers/') + id + '/social',
        data: {
          data: angular.toJson({
            type: vm.socialHandles[index],
            id: vm[isCompany ? 'company' : 'contact'].social[vm.socialHandles[index]]
          })
        },
        spinner: false
      }).then(function(response) {
        if (response.data && response.data.response_code == 200) {
          messages.simpleToast("Saved social link");
        }
      });
    }

    function gotoCustomer(id) {
      $state.go("main.customers.customersDetail", {
        customerId: id
      });
    }

    function initSocialLinks(vm) {
      vm.socialHandles = ['instagram', 'facebook', 'twitter', 'linkedin', 'telegram', 'others'];
    }

    function assignedData(vm, customerId, callback) {
      initSocialLinks(vm);
      vm.gotoCustomer = gotoCustomer;

      dataServices.get({
        url: serverUrl.main + 'contactApi/customers/details/' + customerId + '?',
        spinnerName: $rootScope.customSpinnerName
      }).then(function(response) {
        let data = response.data;
        if (data && data.response_code == 200) {
          vm.contact = data.result;
          if (callback) {
            callback(true);
          }
        }
      });
    }

    function setEmailBounceStatus(vm) {
      vm.emailBounceStatus = {
        primary: vm.contact.emailVerificationStatus == 5,
        secondContact: vm.contact.secondContact,
        secondary: vm.contact.secondContact && vm.contact.secondContact.emailVerificationStatus == 5
      };
      vm.areAllEmailBounced = vm.emailBounceStatus.primary && (!vm.emailBounceStatus.secondContact || vm.emailBounceStatus.secondary);
    }
  }
}